.studio .studio-header .logout {
  padding: 16px; width: 100px;
  height: 100%;
  cursor: pointer;
  vertical-align: top; float: right;
  border-left: 2px solid white;
}
.studio .studio-header .logout:hover {
  background-color: #363636;
}

.studio .studio-header .logout span {
	font-family: Helvetica; font-size: 13px; text-transform: uppercase;
	line-height: 26px;
	text-align: center;
	vertical-align: top;
}
